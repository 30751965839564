import httpClient from '@/utilities/http/http-client'
import httpClientReport from '@/utilities/http/http-client-report'
import _ from 'lodash'

const endpoint = 'api/v1/reports'

export default {
  get (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  getParameters (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/parameters${queryParams}`)
  },

  getParameterLinkedData (idParameter) {
    return httpClient.get(`${endpoint}/parameters/${idParameter}/linkedData`)
  },

  show (idReport) {
    return httpClient.get(`${endpoint}/${idReport}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.patch(`${endpoint}`, params)
  },

  updateCustomScript (params) {
    return httpClient.patch(`${endpoint}/customScript`, params)
  },

  updateParameterDefaultValue (params) {
    return httpClient.post(`${endpoint}/updateParameterDefaultValue`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  },

  run (params) {
    return httpClient.post(`${endpoint}/run`, params)
  },

  templateData (id, params) {
    return httpClientReport.post(`reports/${id}/templateData`, params)
  },

  uploadTemplate (id, params) {
    return httpClientReport.post(`reports/${id}/uploadTemplate`, params)
  },

  getTemplateDownloadUrl (id) {
    const baseUrl = process.env.VUE_APP_REPORT_API_BASE_URL
    return `${baseUrl}/reports/${id}/downloadTemplate`
  },

  generateReportUrl (
    id,
    type,
    params,
    convertTo = 'pdf',
    isDownload = false
  ) {
    const baseUrl = process.env.VUE_APP_REPORT_API_BASE_URL
    let queryParams = {
      data: JSON.stringify({
        params: params,
        convertTo: convertTo
      })
    }
    if (isDownload) {
      queryParams.download = true
    }
    queryParams = new URLSearchParams(queryParams).toString()

    return `${baseUrl}/reports/${id}/generate/${type}?${queryParams}`
  },

  generateReportUsingMockData (params) {
    return httpClientReport.post('reports/generateUsingMockData', params, { responseType: 'arraybuffer' })
  }
}
