<template>
  <div class="h-screen flex flex-col overflow-hidden" :class="{'opacity-50 pointer-events-none': isLoadingInitData}">
    <!--form-->
    <div class="flex-none d-theme-dark-bg">
      <div class="flex flex-row items-center space-x-4 p-2 overflow-auto">
        <div class="flex flex-row items-center space-x-2">
          <label class="text-xs font-medium">Report Name</label>
          <vs-input class="w-72 border border-solid d-theme-border-grey-light rounded-lg" type="text" placeholder="Report Name" v-model="data.description"/>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <label class="text-xs font-medium">Group</label>
          <vs-input class="w-full border border-solid d-theme-border-grey-light rounded-lg" type="text" placeholder="Group" v-model="data.group"/>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <label class="text-xs font-medium">Index</label>
          <vs-input class="w-full border border-solid d-theme-border-grey-light rounded-lg" type="text" placeholder="Index" v-model="data.index"/>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <label class="text-xs font-medium">Code</label>
          <vs-input class="w-full border border-solid d-theme-border-grey-light rounded-lg" type="text" :value="data.code" disabled/>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <label class="text-xs font-medium">ID</label>
          <vs-input class="w-full border border-solid d-theme-border-grey-light rounded-lg" type="text" :value="data.id" disabled/>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <label class="text-xs font-medium">Hide</label>
          <vs-checkbox class="justify-center" v-model="data.is_hidden"/>
        </div>
      </div>
    </div>

    <!--tabs-->
    <div class="flex-none d-theme-dark-bg border border-solid d-theme-border-grey-light">
      <div class="shadow-none rounded-none d-theme-dark-bg">
        <div class="flex h-auto">
          <ul class="flex items-center text-xs overflow-x-auto">
            <li v-for="(tab, index) in tabs.items"
                :key="index"
                :class="{'text-primary font-bold bg-primary-transparent-25': tabs.active === tab.code, 'hover-bg-primary-transparent-25': tabs.active !== tab.code}"
                @click="tabs.active = tab.code"
                class="cursor-pointer p-2 select-none whitespace-no-wrap border-solid d-theme-border-grey-light border-t-0 border-b-0 border-l-0 border-r">
              {{ tab.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--content-->
    <div class="flex-1 flex flex-col d-theme-dark-bg">
      <div class="box_main_content flex flex-col">
        <div class="flex-1 border border-t-0 border-solid d-theme-border-grey-light">
          <div class="h-full w-full">
            <AceEditor ref="aceEditor" v-model="data.query_syntax" @init="onInitAceEditor" lang="mysql" theme="dracula" :options="aceEditorOptions" width="100%"></AceEditor>
          </div>
        </div>
        <div class="flex-none flex flex-row justify-between items-center p-2 border border-t-0 border-solid d-theme-border-grey-light">
          <div class="flex flex-row items-center space-x-2">
            <vs-button color="primary" type="filled" size="small" icon-pack="feather" icon="icon-corner-left-down" class="px-3" :disabled="!data.query_syntax" @click="getQueryParameters">Get Query Param</vs-button>
            <vs-button color="primary" type="filled" size="small" icon-pack="feather" icon="icon-heart" class="px-3" :disabled="!data.query_syntax" @click="beautify">Beautify</vs-button>
          </div>
          <p class="text-xs">{{ data.queryParams.length }} Parameters</p>
        </div>
        <div class="flex-none flex flex-row border border-t-0 border-solid d-theme-border-grey-light">
          <div class="h-64 overflow-auto">
            <table class="table-fixed table-sticky border-collapse w-full">
              <thead class="d-theme-dark-bg" style="background-color: rgba(121,121,121,0.1);">
                <tr>
                  <th colspan="4" class="border border-t-0 border-solid d-theme-border-grey-light p-1 font-semi-bold text-xs text-center whitespace-no-wrap">Parameter</th>
                  <th colspan="3" class="border border-t-0 border-solid d-theme-border-grey-light p-1 font-semi-bold text-xs text-center whitespace-no-wrap">Table Link</th>
                </tr>
                <tr>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-1 font-semi-bold text-xs text-center whitespace-no-wrap w-24">Name</th>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-1 font-semi-bold text-xs text-center whitespace-no-wrap w-20">Title</th>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-1 font-semi-bold text-xs text-center whitespace-no-wrap w-20">Type</th>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-1 font-semi-bold text-xs text-center whitespace-no-wrap w-20">Index</th>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-1 font-semi-bold text-xs text-center whitespace-no-wrap w-20">Table Link</th>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-1 font-semi-bold text-xs text-center whitespace-no-wrap w-20">Key Field</th>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-1 font-semi-bold text-xs text-center whitespace-no-wrap w-20">Alias Field</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(param, index) in data.queryParams" :key="index">
                  <td class="text-xs border border-solid d-theme-border-grey-light text-left">
                    <vs-input class="w-full" type="text" v-model="param.name" readonly/>
                  </td>
                  <td class="text-xs border border-solid d-theme-border-grey-light text-left">
                    <vs-input class="w-full" type="text" v-model="param.alias" placeholder="Title"/>
                  </td>
                  <td class="text-xs border border-solid d-theme-border-grey-light text-left">
                    <vs-select v-model="param.type" class="w-full">
                      <vs-select-item v-for="(it, index) in ['CHARACTER', 'DATE']" :key="index" :value="it" :text="it"/>
                    </vs-select>
                  </td>
                  <td class="text-xs border border-solid d-theme-border-grey-light text-left">
                    <vs-input class="w-full" type="text" v-model="param.index" placeholder="Index"/>
                  </td>
                  <td class="text-xs text-center border border-solid d-theme-border-grey-light text-left">
                    <feather-icon v-if="param.table_link_query" @click="showModalLinkedDataQueryEditor(param)" icon="Link2Icon" svgClasses="h-6 w-6" class="p-2 cursor-pointer hover:text-primary"/>
                    <p class="text-lg cursor-pointer" v-else @click="showModalLinkedDataQueryEditor(param)">-</p>
                  </td>
                  <td class="text-xs border border-solid d-theme-border-grey-light text-left">
                    <vs-input class="w-full m-0 p-0" type="text" v-model="param.table_link_key" placeholder="Key Field" :disabled="!param.table_link_query" :danger="param.table_link_query && !param.table_link_key"/>
                  </td>
                  <td class="text-xs border border-solid d-theme-border-grey-light text-left">
                    <vs-input class="w-full" type="text" v-model="param.table_link_alias_field"  placeholder="Alias Field" :disabled="!param.table_link_query"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!--buttons-->
    <div class="flex-none d-theme-dark-bg border border-t-0 border-solid d-theme-border-grey-light">
      <div class="flex flex-row p-2 space-x-2 justify-start items-center">
        <vs-button color="primary" type="border" size="small" icon-pack="feather" icon="icon-x" @click="onCloseClicked">Close</vs-button>
        <vs-button color="primary" type="filled" size="small" icon-pack="feather" icon="icon-save" :disabled="!data.id || isSaving || !isAnyUnsavedChanges" @click="save">
          <span v-if="isSaving">Saving...</span>
          <span v-else>Save</span>
        </vs-button>
        <span class="text-sm" v-if="data.id && isAnyUnsavedChanges">STATUS: UNSAVED</span>
      </div>
    </div>

    <!--modals-->
    <LinkedDataQueryEditor :isActive.sync="modalLinkedDataQueryEditor.active"
                           :paramName="modalLinkedDataQueryEditor.paramName"
                           :query="modalLinkedDataQueryEditor.query"
                           @saved="onSavedModalLinkedDataQueryEditor"/>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import { format } from 'sql-formatter-plus'
import ReportRepository from '@/repositories/reports/report-repository'
import { getSqlQueryParameters } from '@/utilities/common/global-methods'

export default {
  name: 'ReportEdit',
  components: {
    AceEditor: require('vue2-ace-editor'),
    LinkedDataQueryEditor: () => import('@/views/pages/reports/add/LinkedDataQueryEditor')
  },
  mounted () {
    this.getInitData()
  },
  computed: {
    isAnyUnsavedChanges () {
      let isAnyUnsavedChanges = false

      // compare report header
      const newReport = _.mapValues(_.pick(this.data, ['id', 'code', 'description', 'group', 'index', 'is_hidden', 'query_syntax']), item => ((item || '').toString()))
      const oldReport = _.mapValues(_.pick(this.oldReport.report, ['id', 'code', 'description', 'group', 'index', 'is_hidden', 'query_syntax']), item => ((item || '').toString()))
      if (!_.isEqual(newReport, oldReport)) {
        isAnyUnsavedChanges = true
      }

      // compare report parameters
      const newReportParams = _.map(this.data.queryParams, param => {
        return _.mapValues(_.pick(param, ['id', 'alias', 'index', 'name', 'table_link_alias_field', 'table_link_key', 'table_link_query', 'type']), item => ((item || '').toString()))
      })
      const oldReportParams = _.map(this.oldReport.report_params, param => {
        return _.mapValues(_.pick(param, ['id', 'alias', 'index', 'name', 'table_link_alias_field', 'table_link_key', 'table_link_query', 'type']), item => ((item || '').toString()))
      })
      _.each(newReportParams, newParam => {
        const oldParam = _.find(oldReportParams, item => item.id === newParam.id)
        if (!_.isEqual(newParam, oldParam)) {
          isAnyUnsavedChanges = true
        }
      })

      return isAnyUnsavedChanges
    }
  },
  data () {
    return {
      isLoadingInitData: false,
      isSaving: false,
      tabs: {
        active: 'QUERY',
        items: [
          { code: 'QUERY', title: 'Main Query' }
        ]
      },
      aceEditorOptions: {
        showPrintMargin: false,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        highlightActiveLine: true,
        highlightSelectedWord: true
      },
      modalLinkedDataQueryEditor: {
        active: false,
        uuid: null,
        paramName: null,
        query: null
      },
      data: {
        query_syntax: null,
        queryParams: [],
        is_hidden: 0
      },
      oldReport: {
        report: {},
        report_params: {}
      }
    }
  },
  methods: {
    getInitData () {
      this.isLoadingInitData = true

      const idReport = this.$route.params.idReport
      ReportRepository.show(idReport)
        .then(response => {
          this.oldReport = _.cloneDeep(response.data.data)
          this.buildInitData(response.data.data)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    save () {
      this.isSaving = true

      const params = this.buildParams()
      ReportRepository.update(params)
        .then(response => {
          this.getInitData()
          this.notifySuccess('Berhasil disimpan.')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.notifyErrorUsingDialog(error.response.data.errors)
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    buildInitData (initData) {
      this.data = {
        id: initData.report.id,
        code: initData.report.code,
        index: initData.report.index,
        group: initData.report.group,
        description: initData.report.description,
        query_syntax: initData.report.query_syntax,
        is_hidden: initData.report.is_hidden,
        queryParams: _.map(initData.report_params, item => {
          return {
            uuid: uuid(),
            id: item.id,
            name: item.name,
            type: item.type,
            alias: item.alias,
            index: item.index,
            table_link_query: item.table_link_query,
            table_link_key: item.table_link_key,
            table_link_alias_field: item.table_link_alias_field
          }
        })
      }
    },

    buildParams () {
      return {
        id: this.data.id,
        index: this.data.index,
        group: this.data.group,
        description: this.data.description,
        query_syntax: this.data.query_syntax,
        is_hidden: this.data.is_hidden,
        queryParams: _.map(this.data.queryParams, item => {
          return {
            id: item.id,
            name: item.name,
            type: item.type,
            alias: item.alias,
            index: item.index,
            table_link_query: item.table_link_query,
            table_link_key: item.table_link_key,
            table_link_alias_field: item.table_link_alias_field
          }
        })
      }
    },

    getQueryParameters () {
      const query = this.data.query_syntax
      const params = getSqlQueryParameters(query)
      if (params.length < 1) {
        this.data.queryParams = []
        this.notify('Tidak ada parameter', 'Tidak ada parameter ditemukan pada query.')
      } else {
        this.refreshQueryParameters(params)
      }
    },

    refreshQueryParameters (newParams) {
      // remove
      this.data.queryParams = _.filter(this.data.queryParams, item => {
        return _.findIndex(newParams, param => param === item.name) !== -1
      })

      // add
      _.each(newParams, param => {
        const index = _.findIndex(this.data.queryParams, item => item.name === param)
        if (index === -1) {
          this.data.queryParams.push({
            uuid: uuid(),
            name: param,
            type: 'CHARACTER',
            alias: _.startCase(param).toUpperCase(),
            index: null,
            table_link_query: null,
            table_link_key: null,
            table_link_alias_field: null
          })
        }
      })
    },

    showModalLinkedDataQueryEditor (item) {
      this.modalLinkedDataQueryEditor.uuid = item.uuid
      this.modalLinkedDataQueryEditor.paramName = item.name
      this.modalLinkedDataQueryEditor.query = item.table_link_query
      this.modalLinkedDataQueryEditor.active = true
    },

    onSavedModalLinkedDataQueryEditor (query) {
      const text = query === '' ? null : query
      const index = _.findIndex(this.data.queryParams, item => item.uuid === this.modalLinkedDataQueryEditor.uuid)
      this.data.queryParams[index].table_link_query = text
      this.data.queryParams[index].type = 'CHARACTER'

      // remove
      if (!text) {
        this.data.queryParams[index].table_link_key = null
        this.data.queryParams[index].table_link_alias_field = null
      }
    },

    beautify () {
      this.data.query_syntax = format(this.data.query_syntax, {
        language: 'sql',
        uppercase: true
      })
    },

    confirmLeaveOptions () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Ada perubahan yang belum disimpan, yakin ingin berpindah halaman?',
        acceptText: 'Ya',
        cancelText: 'Tidak'
      }
    },

    onCloseClicked () {
      this.close()
    },

    close () {
      const routeQueryParamCloseType = this.$route.query.close_type || ''
      if (routeQueryParamCloseType === 'CLOSE_WINDOW') {
        window.close()
      } else {
        this.$router.push({ name: 'report', query: { selected_report_id: this.$route.params.idReport } })
      }
    },

    onInitAceEditor (editor) {
      require('brace/ext/language_tools')
      require('brace/ext/searchbox')
      require('brace/mode/mysql')
      require('brace/theme/dracula')
      require('brace/snippets/mysql')
      editor.focus()
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.isAnyUnsavedChanges) {
      this.$vs.dialog({
        ...this.confirmLeaveOptions(),
        accept: () => {
          next()
        }
      })
      return
    }
    next()
  }
}
</script>

<style scoped>
.box_main_content {
  height: calc(100vh - 131px);
}
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
