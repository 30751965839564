import axios from 'axios'

const baseDomain = process.env.VUE_APP_REPORT_API_BASE_URL
const baseURL = `${baseDomain}`

const httpClient = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default httpClient
